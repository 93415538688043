@mixin arrow-button($color: #fff) {
  height: 36px;
  width: 36px;
  @media (min-width: 1024px) {
    height: 42px;
    width: 42px;
  }
  background: rgba(255, 255, 255, 1);
  border-radius: 100%;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  &:after {
    content: '';
    display: block;
    background-image: url('/shared/assets/icons/arrow-main.svg');
    background-repeat: no-repeat;
    font-size: 0 !important;
    height: 100%;
    width: 100%;
    background-position: 55% 50%;
  }
  font-size: 0 !important;
}
