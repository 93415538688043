.checkbox-container-empty {
  display: block;
  position: relative;
  height: 20px;
  width: 20px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: #a93dcb;
      &:after {
        display: none;
      }
    }
  }
  &:hover {
    input ~ .checkmark {
      // For hover styles
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 2px solid #a93dcb;
  }
}
