.checkbox-container {
  display: block;
  position: relative;
  height: 13px;
  width: 13px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: black;
      &:after {
        display: block;
      }
    }
  }
  &:hover {
    input ~ .checkmark {
      // For hover styles
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: white;
    border: 1px solid black;
    &:after {
      left: 4px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      content: '';
      position: absolute;
      display: none;
    }
  }

  &.big {
    height: 16px;
    width: 16px;
    .checkmark {
      height: 16px;
      width: 16px;
      &:after {
        left: 5px;
        top: 2px;
        width: 5px;
        height: 8px;
        border-width: 0 2px 2px 0;
      }
    }
  }

  &.green {
    input {
      &:checked ~ .checkmark {
        border-radius: 1px;
        background-color: #16c270;
        border-color: #16c270;
      }
    }
  }
}
