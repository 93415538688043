.video-slider {
  .swiper-button-prev,
  .swiper-button-next {
    color: white;
  }

  .swiper-button-prev {
    left: 52px;
  }
  .swiper-button-next {
    right: 52px;
  }
}

@media (min-width: 1024px) {
  .frameVideoWrapper {
    .videoInfo {
      opacity: 0;

      &.show { opacity: 1 }
    }
  }
}
