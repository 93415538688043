.react-photo-gallery--gallery {
  overflow: hidden;

  & > div {
    margin: -6px;
    @media (min-width: 1024px) {
      margin: -12px;
    }
  }
}
