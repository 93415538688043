.nav {
  a.screen-link {
    color:#5F6787;
    background: #fff;

    &.active {
      color: initial;
      background-color: #E5E7ED;
    }
  }
}
