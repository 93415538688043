.desktop-gallery {
  .assets-slider {
    .swiper-slide {
      width: var(--asset-item-width) !important;
      .asset {
        .title {
          font-size: var(--asset-title-font-size);
          // line-height: var(--asset-title-line-height);
        }
        .price {
          font-size: var(--asset-price-font-size);
        }
      }
    }
  }
}

.design-layout-nav {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in;
}

.design-layout-nav {
  &.active {
    max-height: 1000px;
    transition: all 0.5s ease-in;
  }
}

.display-mode-content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.display-mode-content {
  &.active {
    max-height: 1000px;
    transition: opacity 0.5s ease-in;
    opacity: 1;
  }
}

.bottom-container {
  height: 58px;
  transition: all 0.5s ease;
  overflow: hidden;
  &.active {
    height: 286px;
    transition: all 0.5s ease;
    &.minimized {
      height: 58px;
    }
  }
}
