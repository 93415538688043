.file-upload-bg {
  background: radial-gradient(
    51.69% 50% at 50% 50%,
    #ffffff 0%,
    #ffffff 0.01%,
    #dfdbdb 34.9%,
    #cdcbcb 53.86%,
    #bdbaba 73.96%,
    #b5b1b1 100%
  );
}
