.swiper-button-prev,
.swiper-button-next {
  color: black;

  &::after {
    font-size: 30px;
    font-weight: bold;
  }
}

.sh-small {
  .swiper-button-prev,
  .swiper-button-next {
    &::after {
      font-size: 14px;
    }
  }
}

.sh-big {
  .swiper-button-prev,
  .swiper-button-next {
    &::after {
      font-size: 24px;

      @media (min-width: 1024px) {
        font-size: 36px;
      }
    }
  }
}

.swiper {
  &.grid-mode {
    display: grid;
    .swiper-wrapper {
      min-width: 0;
    }
  }
}

// special styling for carousel with Assets
.sparx-slider {
  .swiper-slide {
    width: 100px;

    &:last-of-type {
      margin-right: 16px !important;
    }

    @media (min-width: 768px) {
      width: 125px;

      &:last-of-type {
        margin-right: 32px !important;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 62px;

    &::after {
      z-index: 1;
    }
  }

  .swiper-button-prev {
    left: 12px;
  }
  .swiper-button-next {
    right: 12px;
  }

  .swiper-button-disabled {
    opacity: 0;
  }
  .swiper-button-prev,
  .swiper-button-next {
    &::before {
      height: 340px;
      width: 100px;
      content: '';
      background: linear-gradient(270deg, #fafafa 0%, rgba(250, 250, 250, 0) 100%);
      display: block;
      position: absolute;
      z-index: 0;
      top: -70px;
    }
  }
  // INFO: We can use it optionally
  .swiper-button-next::before {
    right: -12px;
  }
  .swiper-button-prev::before {
    left: -12px;
    transform: scaleX(-1);
  }
}
