.iframe-sidebar {
  a.screen-link {
    color:#5F6787;
    padding-left: 14px;

    &.active {
      color: initial;
      padding-left: 0;
      position: relative;
      padding-left: 14px;
      &:before {
        background: black;
        height: 5px;
        width: 5px;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 100%;
        display: block;
        position: absolute;
        border-radius: 5px;
        content: '';
      }
    }
  }
}
