.tableWrap {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  .table {
    background: transparent;

    width: 100%;

    tr {
      height: 57px;
      overflow: hidden;
      border-top: 1px solid #e0e0e0;
      &:first-child {
        border-top: none;
      }
      th {
        text-align: left;
      }

      th,
      td {
        padding: 20px;
        font-size: 14px;
      }

      td{
        cursor: pointer;
        text-decoration: underline;
      }

      &.header {
        th {
            font-weight: 500;
          position: relative;
          &:before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 25%;
            width: 2px;
            background: #e0e0e0;
            content: '';
            display: block;
            left: -5px;
          }
          &:first-child {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}
