@tailwind base;
@tailwind components;
@tailwind utilities;

@import './shared/styles/mixins.scss';

html,
body,
#root {
  height: 100%;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

:root {
  --asset-item-width: 100px;
  --asset-title-font-size: 14px;
  --asset-price-font-size: 12px;
}

////////////////////////////////////////////////////
// Scrollbars
////////////////////////////////////////////////////

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

/* Disable global select */
* {
  -webkit-tap-highlight-color: transparent;
}

.two-line-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.three-line-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.disable-child-select {
  * {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
}

////////////////////////////////////////////////////
// Tooltip
////////////////////////////////////////////////////

.react-tooltip {
  &.default-tooltip {
    z-index: 150;
    background: white;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    color: black;
    padding: 8px 16px;
    opacity: 1;
    display: none;
    max-width: 95vw;
    @media (min-width: 1024px) {
      display: block;
    }
  }
  &.mobile {
    display: block;
  }
}

////////////////////////////////////////////////////
// Global slider overwrites
////////////////////////////////////////////////////

.main-slider,
.assets-slider,
.preview-slider,
.custom-navigation-slider {
  * {
    user-select: none;
  }
  .swiper-button-next,
  .swiper-button-prev {
    @include arrow-button("#fff");
    height: 42px;
    width: 42px;
    position: absolute;
    z-index: 30;
  }

  .swiper-button-prev {
    &:after {
      transform: scaleX(-1);
    }
  }
  .swiper-button-disabled {
    display: none;
  }
}

.main-slider,
.assets-slider,
.preview-slider,
.custom-navigation-slider {
  .swiper-button-next {
    right: 16px;
  }
  .swiper-button-prev {
    left: 16px;
  }
  @media (min-width: 1024px) {
    .swiper-button-next {
      right: 32px;
    }
    .swiper-button-prev {
      left: 24px;
    }
  }
}

.custom-navigation-slider {
  &.rp-slider {
    .swiper-button-next,
    .swiper-button-prev {
      @include arrow-button();
      background: transparent;
      box-shadow: none;
      margin-top: 0;
    }
  }
}

.custom-navigation-slider.no-arrows{
  .swiper-button-next {
    display:none !important
  }
  .swiper-button-prev {
    display:none !important
  }
}


.text-shadow {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

// Animations

@keyframes show {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: flex;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    display: flex;
    opacity: 1;
  }
  99% {
    display: flex;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

.fadeShow {
  animation: show 500ms linear;
  animation-fill-mode: forwards;
}
.fadeHide {
  animation: hide 500ms linear;
  animation-fill-mode: both;
}

.animation-delay-150 {
  animation-delay: 150ms;
}

// Gradients
.gradient-tb {
  background: linear-gradient(180deg, #2c2d31 0%, rgba(44, 45, 49, 0) 100%);
  opacity: 0.4;
}

.gradient-animate-in {
  animation: show 100ms linear;
  animation-fill-mode: forwards;
}

.animate-out {
  animation: hide 100ms linear;
  animation-fill-mode: both;
}

.freeze {
  overflow: hidden;
}

.shadow-sm {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.fadeOut {
  opacity: 0;
  transition: all 0.5s;
  max-height: 0;
}
.fadeIn {
  opacity: 1;
  transition: opacity 0.5s;
  max-height: 9999px;
}

.main-slider {
  .swiper-slide {
    -webkit-transform: translateZ(0);
    -o-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    transform: translateZ(0);
  }
  .swiper-wrapper {
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
  }
}
