.input {
  border: 1px solid #c7ccd9;
  &::placeholder {
    color: #5F6787;
  }
  &:focus{
    outline: none;
    border-color: darken(#c7ccd9, 10);
  }
}
